import * as React from 'react';

import { Footer } from './footer';
import { Nav } from './nav';

const BG_COLOUR_MAP = {
  pink: 'bg-pink',
  teal: 'bg-teal',
  yellow: 'bg-yellow',
};

interface LayoutProps {
  children: React.ReactNode;
  bgColour?: 'pink' | 'teal' | 'yellow';
  showNavAlt?: boolean;
  showFooterAlt?: boolean;
}

function Layout({
  children,
  bgColour = 'yellow',
  showNavAlt = false,
  showFooterAlt = false,
}: LayoutProps): JSX.Element {
  return (
    <>
      <div
        className={`relative flex flex-col min-h-screen font-sans antialiased text-teal fill-available ${BG_COLOUR_MAP[bgColour]}`}
      >
        <Nav showAlt={showNavAlt} />
        <main className="relative flex flex-col flex-1">{children}</main>
        <Footer showAlt={showFooterAlt} />
      </div>
    </>
  );
}

export { Layout };
