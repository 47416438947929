import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityGeneralSettings } from '../hooks/use-sanity-general-settings';
import { useSanitySEOSettings } from '../hooks/use-sanity-seo-settings';
import { Logo } from '../icons/logo';
import { track } from '../utils/fbq';
import { getIcon } from '../utils/get-icon';

interface FooterProps {
  showAlt?: boolean;
}

function Footer({ showAlt = false }: FooterProps): JSX.Element {
  const settings = useSanityGeneralSettings();
  const { siteTitle } = useSanitySEOSettings();
  if (showAlt) {
    return (
      <footer aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="relative block px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl py-12 mx-auto text-center border-t text-cream border-cream">
              Check out our sister company{' '}
              <a
                href="https://www.phirannodesigns.com.au/"
                className="text-lg font-black font-display hover:underline focus:underline"
              >
                Phiranno Designs
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8">
        <div className="relative block px-4 py-12 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <div className="sm:flex sm:items-center sm:space-x-8">
              <Link
                to="/"
                className="block w-full max-w-xs p-4 mx-auto rounded-lg"
              >
                <span className="sr-only">{siteTitle}</span>
                <Logo aria-hidden />
              </Link>
              <div className="flex-1 mt-12 sm:pb-2 sm:mt-auto">
                <div className="space-y-6">
                  <div>
                    <span className="font-black">Call Us: </span>
                    <a href={`tel:${settings.phoneNumber}`}>
                      {settings.phoneNumber}
                    </a>
                  </div>
                  <div>
                    <h3 className="inline text-lg font-black font-display">
                      Email Us{' '}
                    </h3>
                    <a
                      href={`mailto:${settings.email}`}
                      className="whitespace-nowrap hover:underline focus:underline"
                      onClick={() => track({ eventToTrack: 'Contact' })}
                    >
                      {settings.email}
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    {settings.socialLinks.map((link) => {
                      return (
                        <a key={link.id} href={link.url}>
                          <span className="sr-only">{link.icon}</span>
                          {getIcon({
                            socialNetwork: link.icon,
                            className: 'w-6 h-6',
                          })}
                        </a>
                      );
                    })}
                    <span>@thewebsitefactoryco</span>
                  </div>
                  <div className="mt-12">
                    Check out our sister company{' '}
                    <a
                      href="https://www.phirannodesigns.com.au/"
                      className="text-lg font-black font-display hover:underline focus:underline"
                    >
                      Phiranno Designs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
