import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityGeneralSettings } from '../hooks/use-sanity-general-settings';
import { track } from '../utils/fbq';
import { getIcon } from '../utils/get-icon';

interface NavProps {
  showAlt?: boolean;
}

function Nav({ showAlt = false }: NavProps): JSX.Element {
  const settings = useSanityGeneralSettings();
  if (showAlt) {
    return (
      <div className="absolute inset-x-0 top-0 z-10 flex items-center justify-between w-full p-4 mx-auto max-w-screen-2xl sm:p-6 sm:py-4 lg:p-8 text-cream">
        <div>
          <div>
            <h3 className="inline text-lg font-black font-display">
              Email Us{' '}
            </h3>
            <a
              href={`mailto:${settings.email}`}
              className="whitespace-nowrap hover:underline focus:underline"
              onClick={() => track({ eventToTrack: 'Contact' })}
            >
              {settings.email}
            </a>
          </div>
          <div className="flex items-center space-x-2">
            {settings.socialLinks.map((link) => {
              return (
                <a key={link.id} href={link.url}>
                  <span className="sr-only">{link.icon}</span>
                  {getIcon({
                    socialNetwork: link.icon,
                    className: 'w-6 h-6',
                  })}
                </a>
              );
            })}
            <span>@thewebsitefactoryco</span>
          </div>
        </div>
        <div>
          <Link
            to="/"
            className="px-4 py-6 font-black rounded-lg font-display bg-teal focus:ring-2 focus:ring-offset-2 focus:ring-offset-pink focus:ring-teal"
          >
            Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div className="absolute inset-x-0 top-0 z-10 flex items-center justify-between w-full p-4 mx-auto max-w-screen-2xl sm:p-6 sm:py-4 lg:p-8">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                {settings.socialLinks.map((link) => (
                  <a key={link.id} href={link.url}>
                    <span className="sr-only">{link.icon}</span>
                    {getIcon({
                      socialNetwork: link.icon,
                      className: 'w-6 h-6',
                    })}
                  </a>
                ))}
                <span>@thewebsitefactoryco</span>
              </div>
            </div>
            <Popover.Button className="fixed z-10 inline-flex items-center justify-center p-2 rounded-md top-4 sm:top-6 lg:top-8 sm:right-6 lg:right-8 right-4 focus:ring-offset-2 focus:ring-offset-yellow bg-teal focus:outline-none focus:ring-2 focus:ring-teal">
              <span className="sr-only">Open menu</span>
              <MenuIcon aria-hidden className="w-8 h-8 text-cream" />
            </Popover.Button>
          </div>
          <Transition.Root show={open} as={React.Fragment}>
            <Popover.Panel
              static
              className="fixed inset-0 z-30 overflow-hidden"
            >
              <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                  as="div"
                  enter="transition-opacity duration-500 sm:duration-700"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-500 sm:duration-700"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute inset-0 bg-opacity-75 bg-cream"
                />
                <div className="fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={React.Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <div className="w-screen max-w-xl">
                      <div className="flex flex-col h-full overflow-y-scroll shadow-xl rounded-l-2xl bg-teal text-cream">
                        <div className="flex">
                          <span className="sr-only">Mobile menu</span>
                          <div className="flex justify-center flex-shrink-0 w-24 px-2 py-4 border-l sm:py-6 lg:py-8 border-cream sm:px-4 lg:px-6">
                            <span>
                              <Popover.Button className="relative z-10 inline-flex items-center justify-center p-2 rounded-md focus:ring-offset-2 focus:ring-offset-yellow bg-teal focus:outline-none focus:ring-2 focus:ring-teal">
                                <span className="sr-only">Open menu</span>
                                <XIcon
                                  aria-hidden
                                  className="w-8 h-8 text-cream"
                                />
                              </Popover.Button>
                            </span>
                          </div>
                          <div className="flex-1 order-first p-4 sm:p-6 lg:p-8">
                            <div className="py-12 space-y-12">
                              <div>
                                <Popover.Button
                                  as={Link}
                                  to="/#pricing"
                                  className="inline-block text-4xl font-black font-display hover:underline focus:underline"
                                >
                                  Pricing
                                </Popover.Button>
                                <p className="mt-2">
                                  How much for a standard website?
                                </p>
                              </div>
                              <div>
                                <Popover.Button
                                  as={Link}
                                  to="/#your_options"
                                  className="inline-block text-4xl font-black font-display hover:underline focus:underline"
                                >
                                  Our Features
                                </Popover.Button>
                                <p className="mt-2">Want extras?</p>
                              </div>
                              <div>
                                <Popover.Button
                                  as={Link}
                                  to="/#contact"
                                  className="inline-block text-4xl font-black font-display hover:underline focus:underline"
                                >
                                  Contact
                                </Popover.Button>
                                <p className="mt-2">
                                  We’ll answer all your questions
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Second row */}
                        <div className="flex flex-1">
                          <div className="flex flex-col flex-1 p-4 border-t border-cream sm:p-6 lg:p-8">
                            <div className="py-12 space-y-12">
                              <div>
                                <Popover.Button
                                  as={Link}
                                  to="/#what_we_do"
                                  className="inline-block text-4xl font-black font-display hover:underline focus:underline"
                                >
                                  What we do
                                </Popover.Button>
                                <p className="mt-2">
                                  <strong>The Website Factory</strong> is an
                                  experienced team that understands websites. We
                                  created The Website Factory because we know
                                  that having a website is an important asset,
                                  we also understand that not everyone wants a
                                  totally custom designed and developed website,
                                  hence why The Website Factory was created.
                                </p>
                              </div>
                            </div>
                            <div className="mt-auto">
                              <div className="flex flex-col flex-wrap space-y-2">
                                <div>
                                  <h3 className="text-lg font-black font-display">
                                    Call Us
                                  </h3>
                                  <a
                                    href={`tel:${settings.phoneNumber}`}
                                    onClick={() =>
                                      track({ eventToTrack: 'Contact' })
                                    }
                                  >
                                    {settings.phoneNumber}
                                  </a>
                                </div>
                                <div>
                                  <h3 className="text-lg font-black font-display">
                                    Email Us
                                  </h3>
                                  <a
                                    href={`mailto:${settings.email}`}
                                    onClick={() =>
                                      track({ eventToTrack: 'Contact' })
                                    }
                                  >
                                    {settings.email}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center flex-shrink-0 w-24 p-4 border-l border-cream sm:p-6 lg:p-8">
                            <div
                              style={{ writingMode: 'vertical-rl' }}
                              className="flex items-center space-y-4 opacity-60"
                            >
                              {settings.socialLinks.map((link) => {
                                return (
                                  <a
                                    key={link.id}
                                    href={link.url}
                                    className="rotate-90 transform-gpu"
                                  >
                                    <span className="sr-only">{link.icon}</span>
                                    {getIcon({
                                      socialNetwork: link.icon,
                                      className: 'w-8 h-8',
                                    })}
                                  </a>
                                );
                              })}
                              <span className="text-3xl font-black leading-none font-display">
                                @thewebsitefactoryco
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Popover.Panel>
          </Transition.Root>
        </>
      )}
    </Popover>
  );
}

export { Nav };
