import { graphql, useStaticQuery } from 'gatsby';

interface SocialLinks {
  id: string;
  icon: 'Facebook' | 'Instagram' | 'Twitter' | 'YouTube';
  url: string;
}

interface ISanityGeneralSettings {
  email: string;
  id: string;
  phoneNumber: string;
  siteURL: string;
  socialLinks: Array<SocialLinks>;
}

interface ISanityGeneralSettingsQuery {
  sanityGeneralSettings: ISanityGeneralSettings;
}

function useSanityGeneralSettings(): ISanityGeneralSettings {
  const { sanityGeneralSettings } =
    useStaticQuery<ISanityGeneralSettingsQuery>(graphql`
      query SanityGeneralSettingsQuery {
        sanityGeneralSettings(_id: { eq: "generalSettings" }) {
          email
          phoneNumber
          siteURL
          socialLinks {
            id: _key
            icon
            url
          }
        }
      }
    `);
  return sanityGeneralSettings;
}

export { useSanityGeneralSettings };
