/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-call */
// @ts-nocheck
const isBrowser = typeof window !== 'undefined';

interface TrackProps {
  eventToTrack?: string;
}

function track({ eventToTrack = 'Contact' }: TrackProps): void {
  if (isBrowser && window.fbq) {
    window.fbq('track', eventToTrack);
  }
}

export { track };
